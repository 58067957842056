@import '@styles/_variables.scss';
.ab-login {
  flex-direction: row;
  margin-top: -100vh;
  padding: 229px Max(113px, calc((100% - 1440px) / 2 + 113px)) 113px;
  background-color: #f9f6ef;
  @media screen and (max-width: $tablet-breakpoint) {
    padding: 120px 30px 50px;
    flex-direction: column;
  }
  & > form {
    flex: 1;

    & > h3 {
      margin-bottom: 40px;
    }

    & > .ab-input {
      margin-bottom: 48px;
      max-width: 589px;
    }

    & > label {
      & > p {
        color: #372f2f;
      }
    }
    & > .error {
      color: red !important;
      font-family: GT Eesti;
      font-weight: 300;
    }
    & > button {
      @media screen and (max-width: $tablet-breakpoint) {
        max-width: initial;
      }
    }
  }

  & > .right {
    @media screen and (max-width: $tablet-breakpoint) {
      margin-top: 40px;
    }
    width: 100%;
    max-width: 380px;
    & > h3 {
      margin-bottom: 59px;
    }
  }
}
